.box-title {
  width: 100%;
  text-align: center;
  line-height: 1;
  font-weight: 600;
}


.box-title .title-english {
  color: #F0F0F0;
  font-size: 32px;
  margin-top: 6px;
  /* font-weight: 400; */
}

.box-title .title-zh {
  font-size: 34px;
  color: #3B3A43;
}



.box-title .title-zh .num {
  position: relative;
  font-size: 70px;
  top: 4px;
  margin-right: 20px;
}

.box-title .title-zh .text {
  position: relative;
  top: -4px;

}

/* .box-title .title-zh .num::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 56px;
  background-color: #3B3A43;
  transform: rotateZ(45deg);
  top: 12px;
  right: -22px;
} */

.box-footer {
  width: 100%;
  height: 367px;
  background: url('https://qnunion.feierlaiedu.com/kcschool/iH3Iw3slEXZg6d3K4drgNkn6vMOWfXPn') no-repeat;
  background-size: cover;
}
